import MailchimpSubscribe from 'react-mailchimp-subscribe';
import NewsletterForm from './NewsletterForm';

const NewsletterSubscribe = () => {
  const MAILCHIMP_URL =
    process.env.MAILCHIMP_PUBLIC_URL ||
    'https://gmail.us20.list-manage.com/subscribe/post?u=76d175da814dac598d88969d5&amp;id=aa27015b87&amp;f_id=00eb52e6f0';

  return (
    <MailchimpSubscribe
      url={MAILCHIMP_URL}
      render={(props) => {
        const { subscribe, status, message } = props || {};
        return (
          <NewsletterForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        );
      }}
    />
  );
};

export default NewsletterSubscribe;
