import React, { useContext } from 'react';

import './Footer.scss';
import NewsletterSubscribe from './Newsletter/Newsletter';
import { ThemeContext } from '../../../ThemeContext';

const Footer = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      className={`footer ${
        theme === 'dark' ? 'footer__dark' : 'footer__light'
      }`}
    >
      <NewsletterSubscribe />
    </div>
  );
};

export default Footer;
