import React, { useContext } from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';

import { ThemeContext } from '../../../ThemeContext';

import './ProjectCard.scss';

const ProjectCard = (props) => {
  const { theme } = useContext(ThemeContext);

  const cutTextHandler = (text) => {
    if (text?.length > 25) {
      return text.substring(0, 25) + '...';
    }
    return text;
  };

  return (
    <div className={`project-card ${theme === 'dark' ? 'dark' : 'light'}`}>
      <Container>
        <Card bg={theme === 'dark' ? 'dark' : 'light'}>
          <Card.Img
            variant="top"
            src={props.image}
            alt={props.title}
            loading="lazy"
          />
          <Card.Body>
            <Row>
              <Col>
                <Card.Title>{cutTextHandler(props.title)}</Card.Title>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Row
              className={`project-card__links ${
                theme === 'dark' ? 'dark' : 'light'
              }`}
            >
              <Col
                className={`text-center ${theme === 'dark' ? 'dark' : 'light'}`}
              >
                <a
                  href={props.liveUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEye />
                  &nbsp;Live&nbsp;Preview
                </a>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Container>
    </div>
  );
};

export default ProjectCard;
