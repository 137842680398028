export const LINKS = [
    {
        url: '/',
        name: 'Home'
    },
    {
        url: '/work/projects',
        name: 'Projects'
    },
    {
        url: '/blog',
        name: 'Blog'
    },
    // {
    //     url: '/contact',
    //     name: 'Contact'
    // }
];