import React, { useContext } from 'react';
import { ThemeContext } from './ThemeContext';
import { FaMoon, FaSun } from 'react-icons/fa';
import './ThemeToggle.scss';

const ThemeToggle = () => {
    const { theme, toggleTheme } = useContext(ThemeContext);

    return (
        <div>
            <input type="checkbox" className="checkbox" id="checkbox" onClick={toggleTheme} />
            <label for="checkbox" className="checkbox-label">
                <FaMoon className='fa-moon' />
                <FaSun className='fa-sun' />
                <span className="ball"></span>
            </label>
        </div>
    );
};

export default ThemeToggle;
