import { useState } from 'react';
import { sanitize } from '../../../util';
import { AnimatedButton } from '../../UIElements';

import './newsletter.scss';

const NewsletterForm = ({ status, message, onValidated }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      setError('Please enter a valid email address');
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf('@') > -1 && isFormValidated;
  };

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = (event) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if (!message) {
      return null;
    }

    const result = message?.split('-') ?? null;

    if ('0' !== result?.[0]?.trim()) {
      return sanitize(message);
    }

    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? sanitize(formattedMessage) : null;
  };

  return (
    <div className="newsletter">
      <h2>
        <span className="hero__content__title-second">
          Subscribe to my newsletter
        </span>
      </h2>
      <div className="flex newsletter-input-fields">
        <div className="mc-field-group">
          <input
            className="newsletter-input"
            onChange={(event) => setEmail(event?.target?.value ?? '')}
            type="email"
            placeholder="Your email"
            onKeyUp={(event) => handleInputKeyEvent(event)}
          />
        </div>
        <AnimatedButton onClick={handleFormSubmit}>Subscribe</AnimatedButton>
      </div>
      <div className="min-h-42px">
        {'error' === status || error ? (
          <div
            className="text-red-700 pt-2"
            dangerouslySetInnerHTML={{ __html: error || getMessage(message) }}
          />
        ) : null}
        {'success' === status && 'error' !== status && !error && (
          <div
            className="text-green-200 font-bold pt-2"
            dangerouslySetInnerHTML={{ __html: sanitize(message) }}
          />
        )}
      </div>
    </div>
  );
};

export default NewsletterForm;
