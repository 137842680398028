import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { LINKS } from './links';
// import { ethers } from 'ethers';

import { AnimatedButton } from '../../../common/components/UIElements';
import ThemeToggle from '../../../ThemeToggle';
import { ThemeContext } from '../../../ThemeContext';

import './Navigation.scss';

const BadgeSoon = () => {
  return <sup className="badge-soon"> (soon)</sup>;
};

const Navigation = ({ account, setAccount }) => {
  const closeNav = () => {
    const checkbox = document.getElementById('navi-toggle');
    checkbox.checked = !checkbox.checked;
  };

  const { theme, toggleTheme } = useContext(ThemeContext);

  const logoURL =
    'https://ik.imagekit.io/pf0qkwdsv/Portfolio/logo_XG9gdjxCg.png?updatedAt=1666045186506';

  const menuItemColor = theme === 'dark' ? '#fff' : '#828282';

  // const connectHandler = async () => {
  //   const accounts = await window.ethereum.request({
  //     method: 'eth_requestAccounts'
  //   });
  //   const account = ethers.utils.getAddress(accounts[0]);
  //   setAccount(account);
  // };

  return (
    <nav id="navbar">
      <div className="nav__brand">
        <Link to="/">
          {' '}
          {/* <img src="/name_logo.png" alt="logo" />{' '} */}
          <p
            className={`name_logo name_logo__desktop ${
              theme === 'dark' ? 'name_logo__dark' : 'name_logo__light'
            }`}
          >
            Viktor Chobanov
          </p>
          <p
            className={`name_logo name_logo__monogram ${
              theme === 'dark' ? 'name_logo__dark' : 'name_logo__light'
            }`}
          >
            VC
          </p>
        </Link>
      </div>
      <div className="nav__btn">
        <input
          type="checkbox"
          className="nav__btn__checkbox"
          id="navi-toggle"
        />
        <label htmlFor="navi-toggle" className="nav__btn__button">
          <span className="nav__btn__icon">&nbsp;</span>
        </label>
        <div className="nav__btn__background">&nbsp;</div>
        <div className="nav__btn__nav">
          <ul className="nav__btn__list">
            {LINKS.map((link) => {
              return (
                <li
                  className={`nav__btn__item ${theme === 'dark' ? 'dark' : ''}`}
                  onClick={closeNav}
                >
                  <NavLink
                    to={link.url}
                    className="nav__btn__link"
                    style={({ isActive }) => ({
                      color: isActive ? '#ff5403' : menuItemColor
                    })}
                  >
                    {link.name}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="nav__links">
        <ul>
          {LINKS.map((link) => (
            <li className={`${theme === 'dark' ? 'dark' : 'light'}`}>
              <NavLink
                to={link.url}
                style={({ isActive }) => ({
                  color: isActive ? '#ff5403' : menuItemColor
                })}
              >
                {link.name}
              </NavLink>
            </li>
          ))}
          <li className="theme-toggle">
            <ThemeToggle />
          </li>

          {/* {account ? (
            <li>
              <AnimatedButton type="button">
                {account.slice(0, 6) + '...' + account.slice(38, 42)}
              </AnimatedButton>
            </li>
          ) : (
            <li>
              <AnimatedButton
                type="button"
                className="nav__connect"
                onClick={connectHandler}
              >
                Connect
              </AnimatedButton>
            </li>
          )} */}
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
